<template>
	<v-card class="m-0 p-0">
		<v-col cols="12" lg="12" md="12" style="display: contents;">
			<template style="margin-top: 10px;">
				<s-crud
					
					:filter="filter"
					:config="config"				
					addNoFunction
					height="auto"
					no-full
					search-input
					title="Impresion QR"
					@rowSelected="rowSelected($event)"
					print
					@print="printQR($event)"
				>
					<template v-slot:filter>
						<v-container>
							<v-row style="margin-left: 5px" justify="center">
								<v-col cols="12" md="3" lg="3">
									<s-select-definition
										:def="1181"
										v-model="filter.TypeDestiny"
										label="Destino"
									>
									</s-select-definition>
								</v-col>
								<v-col cols="6" md="3" lg="3">
									<s-date
										label="Fecha Inicio"
										v-model="filter.cDateInitial"
									></s-date>
								</v-col>
								<v-col cols="6" md="3" lg="3">
									<s-date
										label="Fecha Fin"
										v-model="filter.cDateFin"
									></s-date>
								</v-col>

								<v-col>
									<s-select-definition
									label="Pallet"
									clearable
									v-model="filter.Process"
									:def="1395">

									</s-select-definition>
								</v-col>
							</v-row>
						</v-container>
					</template>

					<template v-slot:RcfID={row}>
						<v-chip
							small
							color="success"
						>
							{{row.RcfID}}
						</v-chip>
					</template>

					<template v-slot:RcfNumberHarvestGuide={row}>
						<v-chip
							small
							outlined
							color="info"
						>
							{{row.RcfNumberHarvestGuide}}
						</v-chip>
					</template>

					<template v-slot:xCount={row}>
						<v-chip
							x-small
							:color="row.xCount >= 1 ? 'info' : 'error'"
						>
							{{row.xCount >= 1 ? 'Abastecido' : 'Sin Abast.'}}
						</v-chip>
					</template>

					<template v-slot:Process={row}>
						<v-chip
						 x-small
						 :color="row.Process == 1 ? 'success' : 'warning'">
							{{row.Process == 1 ? 'Reproceso':'Normal' }}
						</v-chip>
					</template>
				</s-crud>
			</template>
		</v-col>
		<v-row justify="center" cols="12" style="display: none">
			<v-col cols="12" v-for="item in selected">
				<qr-code
					:text="
						item.LlpID +
							', Jabas: ' +
							item.LlpQuantityJaba +
							',' +
							item.TypeCaliberName +
							',' +
							item.TypeCultiveName +
							',' +
							item.VrtName +
							',' +
							item.TypeDestinyName +
							',' +
							item.TypeDestiny
					"
					error-level="H"
					hidden
				>
				</qr-code
				><br />
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";
	import qrCode from "vue-qr-generator";
	import { now } from 'moment';

	export default {
		props: {},
		components: { qrCode },
		data: () => ({
			filter: {
				TypeDestiny: 1,
				cDateInitial: null,
				cDateFin: null,
				Process: null,
			},
			config: {
				service: _sLotProdCalibratedPrintQRService,
				model: {
					LlpID: "ID",
					RcfID: "",
					RcfNumberHarvestGuide: "",
					xCount:"",
					Process: "",
				},
				headers: [
					{ text: "Codigo", value: "LlpName", width: 100 },
					{ text: "Lote Producción", value: "RcfID", width: 150 },
					{ text: "Tarima", value: "ScfCode", width: 200 },
					{ text: "Cant. Jaba", value: "LlpQuantityJaba", width: 100 },
					{ text: "Calibre", value: "TypeCaliberName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Estado", value: "xCount", width: 100},
					{ text: "Tipo Pallet", value:"Process", width: 100},
				],
			},
			selected: [],
			openDialog: true,
			itemsDetail : [],
			WbbNetWeight: 0,
		}),

		methods: {
			click(val) {
				this.filter.TypeDestiny = val.DedValue;
				this.openDialog = false;
			},

			rowSelected(val) {
				if (val !== undefined) {
					this.selected = val;

					if(this.selected.length > 0) {
						console.log("selected", this.selected);

						_sLotProdCalibratedPrintQRService.GetDetailQR(this.selected[0].LlpID, this.$fun.getUserID()).then(resp => {
							if (resp.status == 200) {
								this.itemsDetail = resp.data;
								this.WbbNetWeight = 0;
								this.itemsDetail.forEach(element => {
									this.WbbNetWeight += element.WbbNetWeight
								});

								console.log("itemsDetail", this.itemsDetail);
							}
						});
					}
					
				}
			},

			
			printQR() {
				var imgAll = document.querySelector("img");
				

				var daten = new Date().toLocaleString();

				// let ScfCode = "";
				// if (this.selected[0].ScfCode != "") {
				// 	ScfCode = "<h2 style='margin: 0px'><b>Tarima: " + this.selected[0].ScfCode + "</b></h2>";
				// }

				// let ScfCode = "";
				// if (this.selected[0].ScfCode != "") {
				// 	ScfCode = "<h2><b>Tarima: " + this.selected[0].ScfCode + " || REPROCESO</b></h2>";
				// } else {

				// 	ScfCode = "<h2><b>REPROCESO</b></h2>";
				// }

				let ScfCode = "";
				if (this.selected[0].ScfCode != "") {
					ScfCode = "<h2 style='margin: 0px'><b>Tarima: " + this.selected[0].ScfCode + " ||</b></h2>";
				} 

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' /></div>",
					);

					pwa.document.write("<div ><table border='0'>");
					pwa.document.write(
								"<tr><td colspan='4'>Datos Generales: " + 
								"<tr>" +
								"<td>" + "<b>"+this.selected[0].LlpID + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeCultiveName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].VrtName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeCropName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeDestinyName + "</b>"+
								"</td><td> ||  </b></td></tr>" +
								/* "</td><td> || " + "<b>" + this.WbbNetWeight.toFixed(2)+"</b>"+ */
								"<tr><td colspan='5'>" + this.selected[0].TypeCaliberName + "</td></tr>"	+ 
								" </table></div>"  + ScfCode  +  "<span class='h1' style='font-size: large;'><b>" + this.selected[0].TypeBsnLineName + "</b></span><br>" 
								 + " <span><b> N° Faja: " + this.selected[0].NumberGirdle +  "</b</span>"
					);
					pwa.document.write("");

					this.itemsDetail.forEach(detail => {
							
							pwa.document.write("<div ><table border='0'>");

							pwa.document.write(
								"<tr ><td colspan='3'>Lote Produccion: " + detail.RcfID + "-" + detail.RcfCode + ", Jaba: " + detail.LppQuantityJaba  +", Kg: " + detail.WbbNetWeight.toFixed(2) +"</td></tr>"
								/* "<tr><td>Lote Produccion: </td><td>" + detail.RcfID +"</td></tr>" +
								"<tr><td>Jabas: </td><td>" + detail.LppQuantityJaba +"</td></tr>" */
							);

							pwa.document.write(
								// "<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								// "<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + " || "+ detail.FltCodSenasa + "</td></tr>" + 
								"<tr><td>Procedencia: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							pwa.document.write(
								"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>",
							);
							pwa.document.write(
								"<tr><td>Posición: </td><td>" + detail.LppPosition +"</td></tr>",
							);
							
							pwa.document.write(
								"<tr><td>Fecha Creación: </td><td>" + detail.PrintCreate +"</td></tr>",
							);

							pwa.document.write(
								"<tr><td>Creado por: </td><td>" + detail.NtpName +"</td></tr>",
							);

							pwa.document.write(
								"<tr><td>Fecha Impresión: </td><td>" + daten +"</td></tr>",
							);

							pwa.document.write("</table>-------------------------------------------------------------</div>");

							pwa.document.write("</table></div>");
						});

				/* }); */

				

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},
		},
	};
</script>

<style></style>
